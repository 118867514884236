.btn-link {
    font-weight: 400;
    color:   #0e161d;

}
.btn-link:hover {
    color: rgba(255, 255, 255, 0.09);
    color:   #0e161d;
    z-index: 1000;
}

.header_icons_right{
   color: #0e161d;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    padding-right:0;
    z-index: 1000;

}
.btn:hover {
    color:#009fbf;
}

.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: rgba(32, 33, 38, 0);
    border-color: #009fbf;

}


.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
    border: none;
}
.dropdown-item.disabled, .dropdown-item:disabled {
    color:#009fbf;
    cursor:pointer;
    background-color:  #0e161d;
    z-index: 1000;
}
.active, .dropdown-item:active {
    color: #0e161d;
    text-decoration: none;
    background-color: #0e161d;

}

.hover,.dropdown-item:focus {
    color:white;
    text-decoration: none;
    background-color: #0e161d;

}
.dropdown-item:hover {
    color: white;
    text-decoration: none;
    background-color: #0e161d;

}


