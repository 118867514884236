.home_Mission {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100vw;
  height: auto;
  background: url("../aboutUs/Images_face/img_11.png")  no-repeat;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-size: cover;

}



.Mission {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100vw;
  margin: 190px 150px 220px 150px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

.Mision_img{
  width: 342.01px;
  height: 354px;
  img{
    margin-top: 50px;
  }
}
  .Mission_text {
    width: 750px;

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      letter-spacing: 0.005em;
      color: #FFFFFF;

    }

    hr {
      width: 69px;
      height: 3px;
      background: #009FBF;
      border-radius: 3px;
    }
    .next{

        margin-top: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.1px;
        color: #FFFFFF;
        margin-bottom: 20px;

    }

    p {

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140.69%;
      color: #FFFFFF;


    }
  }


}

.home_ourStory {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100vw;
  background: #F4F7F9;


  .heading-primary {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    font-style: normal;
    width: 100vw;
    margin-top: 100px;


    .Meet_text {
      margin-right: 150px;
      margin-left: 150px;

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 45px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #52515F;

      }

      hr {
        width: 69px;
        height: 3px;
        background: #009FBF;
        border-radius: 3px;

      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.1px;
        color: #52515F;
      }

    }
  }
}

.home_AboutAs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  width: 100vw;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .Story {
    margin-top: 100px;
    hr {
      margin: 0;
      background-color: currentColor;
      border: 0;
      opacity: 0.25;
    }
  }
}

.Story_wrapper_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100vw;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .Story_text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 100px 150px 150px 150px;

    .text_Almost {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: start;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;

      h2 {

        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: #22313F;


      }

      hr {

        width: 69px;
        height: 3px;
        background: #009FBF;
        border-radius: 3px;
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 153%;
      color: #52515F;
    }

    h5 {
      margin-top: 50px;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 153%;
      color: #52515F;
    }
  }


}


.piple_blokc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;


  .scene {
    width: 1200px;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-perspective: 800px;
    perspective: 800px;


    .dogs {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 100vw;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      margin-bottom: 50px;

    }

    .cart_text_name {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
        -webkit-font-feature-settings: 'salt' on, 'liga' off;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #52515F;

      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #52515F;
        margin-top: -25px;

      }

      img{
        width: 22px;
        height: 22px;
        margin-top: -55px;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: end;
        margin-left: 250px;
      }

    }
    .team-card {

      position: relative;
      margin-bottom: 20px;
      margin-top: 75px;
      border-radius: 10px 10px 0 0;
      width: 277px;
      height: 376px;
      &:hover {
        .card-front {
          -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
        }
        .card-back {
          -webkit-transform: rotateY(0);
          transform: rotateY(0);
        }
      }
      .card-front, .card-back {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
       transition: 0.3s;
      }
      .card-back {
        overflow: hidden;
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);

        img {
          bottom: 0;
          border-radius: 10px 10px 0 0;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 278px;
          height: 377px;
          object-fit: cover;
        }
      }
    }

  }


}

.home_Values {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100vw;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

}

.Values_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-image: url("../aboutUs/Images_face/img_20.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.Values_number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100vw;
  height: 500px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: 150px;
  margin-right: 150px;

  .Values_canter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 50px;
      line-height: 36px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #009FBF;
    }

    h5 {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      -webkit-font-feature-settings: 'salt' on, 'liga' off;
      font-feature-settings: 'salt' on, 'liga' off;
      color: #FFFFFF;
    }
  }

}

.Values_text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 500px;
  background-color: #018CA8;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 0 0 100px 0;

  h2 {
    margin-top: -150px;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 160%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #FFFFFF;

  }

  h6 {

    font-style: italic;
    font-weight: 300;
    font-size: 26px;
    line-height: 136.19%;

    color: #FFFFFF;
    opacity: 0.75;
  }

  p {
    width: 500px;
    margin-top: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.75;
  }
}

.Values_text_boxs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 1200px;

  .Values_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    padding: 40px 20px 0 20px;
    margin-top: -150px;
    width: 277px;
    height: 300px;
    left: 1015px;
    top: 5271px;
    background: #F4F7F9;
    border-radius: 20px;
    .box_4{
      height: 65px;
    }
    h4 {

      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      -webkit-font-feature-settings: 'salt' on, 'liga' off;
      font-feature-settings: 'salt' on, 'liga' off;
      color: #009FBF;

    }
    p{
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: #52515F;
    }
  }
}
.home_Milestones{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .Milestones_wrapper{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width:800px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 150px;
    margin-bottom: 70px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .Milestones_text{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 400px;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      h2{
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 45px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #52515F;
      }
      hr{
        width: 69px;
        height: 3px;
        background: #009FBF;
        border-radius: 3px;
      }
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #62626C;
      }
    }

    .Milestones_img{
      margin-right: 50px;
      img{
        margin-top: 5px;
        height: 380px;
      }

    }
    .Milest_1_0{
      img{
        width: 32px;
        height: 22px;
        margin-top: -665px;
        margin-left: -9.5px;
      }



    }
    .Milest_1_1{
      img{
        width: 20px;
        height: 20px;
        margin-top:-460px;
        margin-left:-8px;
      }
    }
    .Milest_1_2{
      img{
        width: 20px;
        height: 20px;
        margin-top:-140px;
        margin-left:-8px;
      }
    }

    .Milestones_box{
      width: 200px;
    }
    .Milestones_boxs{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 340px;
      .box_description{
        margin-bottom: 70px;
        h4{
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-font-feature-settings: 'salt' on, 'liga' off;
          font-feature-settings: 'salt' on, 'liga' off;
          color: #009FBF;
        }
        h3{
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          color: #52515F;

        }
      }
    }

  }
}
@media screen and (max-width: 1400px){
  .Values_number {

    margin-left: 50px;
    margin-right: 50px;
  }
  .Story_wrapper_text .Story_text {

    margin: 100px 20px 20px 20px;
  }
  .home_ourStory .heading-primary .Meet_text {
    margin-right: 20px;
    margin-left: 20px;
  }
  .Values_text {
    align-items:center;
  }
  .Values_text_boxs .Values_box {

    margin-top: 50px;


  }
  .Mission {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin: 180px 20px 20px 20px;
    flex-wrap: wrap;
  }
  .Mission .Mission_text {
    width: auto;
  }
  .Values_text_boxs {
    justify-content: space-around;
    width: 100vw;
    margin-top: 20px;
  }
  .Mission .Mision_img {
  margin-top: 50px;
    margin-bottom: 50px;
  }
  .piple_blokc .scene {
    width: auto;

    justify-content: space-around;

  }

}

@media screen and (max-width: 780px) and (max-width: 1000px) and (max-width: 1200px) {

  .Mission .Mision_img {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .home_AboutAs .Story {
    margin-top: 40px;
  }
  .Mission {
    margin: 210px 20px 10px 20px;
    height: auto;

    .Mission_text {
      width: auto;

      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 38px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

      }
    }

    .Mission_p {
      width: 340px;

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0.1px;

      }
    }
  }

  .Mission .Mision_img {

  }
  .Story_wrapper_text {
    border-radius: 0;

    .Story_text {
      margin: 50px 20px 70px 20px;
    }


  }
  .Story_wrapper_text {
    .Story_text {
      h5 {
        margin-top: 0;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 152.5%;


      }

      .text_Almost {
        h2 {
          font-style: normal;
          font-weight: 400;
          font-size: 26px;
          line-height: 30px;
        }

        hr {
          margin-left: 0;
        }


      }
    }

    .Story_text p {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 152.5%;
      color: #52515F;

    }
  }

  .Story {
    margin-top: 50px;
  }

  .home_ourStory .heading-primary {
    margin-top: 0;
  }
  .home_ourStory .heading-primary .Meet_text {
    width: auto;
    margin: 40px 20px 0 20px;

    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 38px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  .home_ourStory .heading-primary .Meet_p {
    width: 340px;
    margin: 5px 20px 0 20px;

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.1px;
    }
  }
  .home_ourStory .heading-primary {
    margin-bottom: 0;
  }
  .piple_blokc .scene {
    width: 100vw;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-around;

  }
  .piple_blokc .scene .card {
    margin-top: 35px;

  }
  .dogs {
    margin-bottom: 30px;

  }
  .Values_number {
    height: auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 80px;
    margin-left: 100px;
    margin-right: 100px;
  }

  .Values_number .Values_canter h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.03em;
    margin-top: 40px;
  }

  .Values_number .Values_canter h5 {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    -webkit-font-feature-settings: 'salt' on, 'liga' off;
    font-feature-settings: 'salt' on, 'liga' off;
    margin-top: 10px;
  }

  .Values_text {
    height: auto;

  }
  .Values_text_boxs {
    width: 100vw;

  }
  .Values_text_boxs .Values_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    padding: 40px 20px 0 20px;
    margin: 40px 0 10px 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 340px;
    height: 318px;
    background: #F4F7F9;
    border-radius: 20px;
  }
  .Values_text_boxs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-around;
    width: 100vw;
    margin-top: 20px;
  }
  .home_Milestones {

    margin: 0;
  }
  .home_Milestones .Milestones_wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100vw;
  }
  .home_Milestones .Milestones_wrapper .Milestones_text {
    width: 340px;

  }
  .home_Milestones .Milestones_wrapper .Milestones_text h2 {
    margin-top: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #52515F;
  }
  .home_Milestones .Milestones_wrapper .Milestones_text h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: #52515F;
  }
  .home_Milestones .Milestones_wrapper .Milestones_boxs .box_description h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #52515F;

  }

  .Values_text {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    h2{

      margin-left: 10px;
      margin-top: 60px;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 160%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #FFFFFF;
    }
    h6{
      margin-left: 10px;
      font-style: italic;
      font-weight: 500;
      font-size: 16px;
      line-height: 136.19%;
      color: #FFFFFF;
      opacity: 0.75;
    }
  }

  .Values_text p {
    width: 347px;
    text-align: start;
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    opacity: 0.75;
    margin-bottom: 80px;

  }
  .home_Milestones .Milestones_wrapper .Milestones_boxs .box_description {
    margin-bottom: 80px;
  }
  .box_description{


  }

  .Values_text_boxs .Values_box h4 {
    font-size: 19px;

  }

  .Values_text_boxs .Values_box p {
    margin-top: -20px;
  }
  .home_Milestones .Milestones_wrapper {
    .Milest_1_1 {
      img {

        margin-top: -455px;

      }
    }
  }
  .home_Milestones .Milestones_wrapper {
    margin-top: 30px;

  }
  .Milestones_wrapper .Milestones_boxs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 340px;
    margin-top: 40px;
  }
}