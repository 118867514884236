

@mixin white-gradient {
  background: linear-gradient(to right,  rgb(190, 184, 184) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 15s;

// Animation
@keyframes animatedBackground {
  from { background-position: 0 0; }
  to { background-position: -562px 0; }
}


// Styling
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
  overflow:hidden;
  position: relative;
  width: 100vw;
  margin: 0 auto;
  transform: translate3d(0, 0, 0);
  &::before,
  &::after {
    @include white-gradient;
    content: "";
    height: 150px;
    position: absolute;
    z-index: 2;
  }

  &::after {
    right:0;
    top:0;
    transform: rotateZ(150deg);
  }

  &::before {
    left: 0;
    top: 0;
  }

  .slide-track {

    animation: animatedBackground 25s linear infinite  ;
    line-height: 300px;
    background-repeat: repeat-x;
    background-image:url('./5188.png') ;
    height: 150px;
    width: 100%;
  }


}

