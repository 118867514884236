.footer_wrapper {
  width: 100vw;
  background: #0e161d;
  padding: 6rem 0 2rem;

}

.triangular {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 47.3%;
  width: 0;
  height: 0;
  border-left: 34px solid transparent;
  border-right: 34px solid transparent;
  border-bottom: 33px solid #0e161d;
  margin-top: -125px;


}

.footer_description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 100px 110px 0 150px;


}

.footer_top {
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: space-around;
  width: 1000px;
  flex-wrap: wrap;

  .footer_section_1, .footer_section_2, .footer_section_3, .footer_section_4,
  .footer_section_5, .footer_section_6 {
    align-items: center;
    width: 240px;
    margin-right: 80px;
    margin-top: 60px;
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      font-feature-settings: 'salt' on, 'liga' off;
      color: #9a9999;

    }
    hr{
      border: 1px solid rgba(196, 196, 196, 0.25);
      margin-top: 5px;
    }


    li {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      font-feature-settings: 'salt' on, 'liga' off;
      color: #FFFFFF;
      &:hover {
        color:  #009fbf;
        cursor: pointer;
      }
      &::before {
        content: "";
        position: absolute;
        width: 240px;
        height: 1.5px;
        background-color:  #009fbf;
        transition: 0.2s;
        transform: scaleX(0);
        margin-top: 29px;

      }

      &:hover:before {
        transform: scaleX(1);

      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
      }

    }

    .media{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 90px;
    }
    .singnup {
      margin-left: 0;
    }
    a {
      &::before {
        content: "";
        top: 31.5px;
        position: absolute;
        width: 100%;
        height: 1.5px;
        background-color: #009fbf;
        transition: 0.2s;
        transform: scaleX(0);
      }

      &:hover:before {
        transform: scaleX(1);
      }
    }
    }



  .footer_section_4 {
    margin-top: -30px;
  }




  .footer_section_6 {
    margin-top: -4px;

  }

  .footer_section_1, .footer_section_2, .footer_section_3, .footer_section_4,
  .footer_section_5{

    a {
      color: white;
      &:hover {
        color:  #009fbf;
        cursor: pointer;
      }

      &::before {
        top: 27px;
        width: 0;

      }

    }
  }



}


.footer_map {

  margin-top: -205px;

  img {
    margin-top: 60px;
    height: 150px;

  }

  hr {
    border: none;
    background-color: white;
    height: 2px;
    position: relative;
    right: 160px;
    bottom: 120px;
    width: 260px;
  }

  .map_logo {
    img {
      position: relative;
      top: 105px;
      left: 95px;
      width: 13px;
      height: 13px;
    }

  }
}



  .footer_private {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

p{
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;

}


}



@media screen and (max-width: 180px) {
  .footer_description {
    justify-content: space-between;
  }
}
@media screen and (max-width: 1500px){
  .footer_map {
    display: none;
  }

  .footer_top {
    justify-content: space-between;
    width: 100vw;
  }

}
@media screen and (max-width: 1230px){

  .footer_top {
    justify-content: space-between;
    width: 100vw;
  }
  .footer_description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 60px 50px 0 50px;
  }

}

@media screen and (max-width: 1100px){


  .footer_description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 60px 50px 0 50px;
  }
  .footer_top .footer_section_1, .footer_top .footer_section_2, .footer_top .footer_section_3, .footer_top .footer_section_4, .footer_top .footer_section_5, .footer_top .footer_section_6 {
    width: 250px;
    margin-right: 0;

  }
}
@media screen and (max-width: 850px){



  .footer_top .footer_section_1, .footer_top .footer_section_2, .footer_top .footer_section_3, .footer_top .footer_section_4, .footer_top .footer_section_5, .footer_top .footer_section_6 {
    width: 210px;
    margin-right: 0;

  }
}
@media screen and (max-width: 780px){



  .footer_top .footer_section_1, .footer_top .footer_section_2, .footer_top .footer_section_3, .footer_top .footer_section_4, .footer_top .footer_section_5, .footer_top .footer_section_6 {
    width: 210px;
    margin-right: 0;

  }
}
@media screen and (max-width: 780px) and (max-width: 1000px) and (max-width: 1200px) {

  .triangular {
    margin-left: 41%;
  }
  .footer_description {
    padding: 0;

    .footer_left {
      width: 100vw;
      flex-wrap: wrap;
      font-size: 14px;
      line-height: 19px;
      div {
        width: 50%;
      }

    }

  }

  .footer_top{
    justify-content: space-between;
    padding-left: 25px;


    .footer_section_1{
      order: 1;
    }
    .footer_section_2{
      order: 3;
    }
    .footer_section_3{
      order:6;
    }
    .footer_section_4{
      order: 5;
    }
    .footer_section_5{
      order: 3;
    }
    .footer_section_6{
      order:6;
      margin-bottom: 40px;
    }

    .footer_section_1, .footer_section_2, .footer_section_3, .footer_section_4,
    .footer_section_5, .footer_section_6{
        margin-top: 40px;
        width: 300px;
        margin-right: 20px;

      h2{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #C4C4C4;

      }
      li, p{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        font-feature-settings: 'salt' on, 'liga' off;
        color:white;


        &::before {
          width: 300px;
          margin-top: 25px;
          margin-right: 20px;

        }
      }
      hr{
        border: 1px solid rgba(196, 196, 196, 0.25);
        width: auto;


      }

    }
  }


  .footer_wrapper {
    height: auto;
  }
  .footer_right_logo {
    display: none;
  }
  .footer_social {
    .header_icons_left {
      width: 180px;
      margin-top: 0;
      margin-left: 0;
      padding-bottom: 2%;
      justify-content: start;

    }

    .singnup {
      margin-right: 20px;
      padding-left: 5%;


    }

  }
  .footer_rate_mobile {
    justify-content: space-between;
    font-size: 12px;
  }
  .footer_map {
    display: none;
  }
  .footer_address {
    display: flex;
    align-items: center;
    justify-content: center;


    p{
      text-align: center;
    }


    .footer_private {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      flex-flow: row-reverse wrap-reverse;

hr{

    border: none;
    color: white;
    background-color: white;
    height: 1px;
    position: relative;
    width:145px;
    top: -20px;

}
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #D9DBE1;

      }
      .private{
        p{
          font-style: normal;
          margin-bottom: 3px;
          font-weight: 300;
          font-size: 11px;
          line-height: 24px;
          text-decoration-line: underline;
          font-feature-settings: 'salt' on, 'liga' off;
          color: #D9DBE1;
        }
      }

    }


  }

}

