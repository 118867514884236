.contact_wrapper_login{
  width: 100vw;
  height: auto;
  flex-wrap: wrap;
  background-image: url("../../components/carousel/imageCarousel/img_7.png");
  background-size: cover;
  background-repeat: no-repeat;

  .login_back{
    display: flex;
    justify-content: start;
    align-items: start;
    margin-left: 50px;
  }

.logon{
  width: 120px;
  height: 28.33px;
}
  .formname{
    display: flex;
    align-items: start;
    justify-content: start;
    width: 350px;


  }

  .formname input[type=checkbox] + label {
    display: block;
    position: relative;
    margin: 0 0 12px 30px;
    cursor: pointer;
    font-size: 15px;
    line-height: 20px;
    color: #333333;


  }
}

.logo_address{
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  img{
    height: 22px;
    width: 120px;
    margin-bottom: 20px;
  }
  p{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
}

.wrapper_forms{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: auto;
}
@media screen and (max-width: 780px) and (max-width: 1000px) and (max-width: 1200px)  {
  .contact_wrapper, .contact_wrapper_login {
    .login_back {
      margin-left: 10px;
    }
  }
  .logo_address{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    p{

      font-size: 11px;

    }
  }

}







