.home_product_1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #F4F7F9;
}

.home_product {
  display: flex;
  align-items: center;
  flex-wrap: wrap;


  .StepUs_mobail{
    display: none;
    .OurStep_text_4, .OurStep_text_3, .OurStep_text_2, .OurStep_text_1{
      display: none;
    }
  }


}

.Product_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  background: #0C1B26;
  border-radius: 0 0 100px 0;
}

.product_Data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100vw;
  flex-wrap: wrap;

  margin: 175px 0 0 0;

  .tex_product_wrapper {
    display: flex;
    align-items: center;
    margin: 0 150px 0 150px;
    width: 100vw;
    justify-content: space-between;
    flex-wrap: wrap;


    .product_text {
      width: 640px;

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;
        color: #FFFFFF;
      }

      h4 {
        margin-top: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 140.19%;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      hr {
        width: 69px;
        height: 3px;
        background: #009FBF;
        border-radius: 3px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140.69%;
        color: #FFFFFF;
      }
    }

    .product_image {
      img {
        position: absolute;

        width: 350px;
        height: 330px;


      }
    }

    .product_image_spader {
      margin-top: 60px;
      img {
        width: 342.01px;
        height: 354px;
      }
    }
  }

}

.product_text_box {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 70px;

  h2 {
    text-align: center;
    width: 390px;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    letter-spacing: 0.005em;
    color: #009FBF;


  }

}

.product_icon_boxes {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  .product_boxes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    flex-wrap: wrap;
    margin: 80px 150px 50px 150px;
    .product_box {
      text-align: center;
      width: 231px;
      height: 230px;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 119.69%;
        text-align: center;
        color: white;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        color: white;
      }

      img {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }


    }

  }

}

.Culture_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;





  .Culture_text {
    display: flex;
    align-items: start;
    justify-content:start;
    width:50vw;
    height: 500px;
    padding: 150px;
    flex-wrap: wrap;


    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      align-items: center;
      letter-spacing: 0.005em;
      color: #8CAAC3;
      text-align: start;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      color: #52515F;

    }


  }

  .Culture_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 500px;
    background: #F4F7F9;
    border-top-left-radius: 100px;


    img {
      width: 300px;
      height: 300px;

    }
  }


}


.Strategy_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;


  .Strategy_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 500px;
    background: #F4F7F9;
    border-bottom-left-radius: 100px;


    img {
      width: 234px;
      height: 368px;;
    }
  }

  .Strategy_text {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    width: 50vw;
    height: 500px;
    padding: 150px;

    h2 {

      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;


    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      color: #52515F;
    }

  }


}

.organization_product {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 279px;
  background: #009FBF;
  border-radius: 20px;
  margin-bottom: 120px;

  .organization_wrapper_product {

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: #FFFFFF;
      text-align: center;
    }

    .sing_In {
      width: 178px;
      height: 45px;
      padding: 15px;
      display: flex;
      justify-content: center;
      border: 2px solid transparent;
      border-radius: 99em;
      margin-left: 29%;
      margin-top: 24px;
      bottom: 32.77%;
      background: #FFFFFF;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      .Button-Inner {
        font-weight: 400;
        font-size: 16px;
        line-height: 86.5%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #037E97;

        &:focus {
          border-color: #fff;
          box-shadow: 0 0 0 2px #009fbf;
        }

        &:hover {
          filter: brightness(1.1);
          color: #272b31;
        }

        &:active {
          filter: brightness(0.9);
        }
      }
    }

  }

}

.OurStep_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
  margin: 70px 0 130px 0;
  flex-direction: row;

  .Process_text {
    display: flex;
    flex-direction: column;
    align-items: start;


    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 132%;
      display: flex;
      align-items: center;
      color: #52515F;
    }

    hr {
      width: 69px;
      height: 3px;
      background: #009FBF;
      border-radius: 3px;
    }
  }

  .Process_title {
    display: flex;
    align-items: start;
    width: 500px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 149.19%;
      color: #52515F;

    }
  }



  .OurStep_image {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;


    .OurStep_text_1, .OurStep_text_2, .OurStep_text_3, .OurStep_text_4 {
      display: flex;
      top: 26%;
      left: 6%;

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.1px;
        color: #000000;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 112.19%;
        color: #000000;
      }
    }

    img {
      width: 100vw;


    }


  }
}

.OurStep_text_2 {
  position: absolute;
  margin-top: -45%;
  left: 30%;
  width: 200px;
  height: auto;
}

.OurStep_text_1 {
  position: absolute;
  margin-top: -58%;
  left: 18.5%;
  width: 290px;
  height: auto;
}

.OurStep_text_3 {
  position: absolute;
  margin-top: -50%;
  right: 23%;
  width: 160px;
  height: auto;
}

.OurStep_text_4 {
  position: absolute;
  margin-top: -30.3%;
  right: 2%;
  width: 200px;
  height: auto;
}

.OurStep_text_3, .OurStep_text_4 {


  h6 {
    color: #009FBF;
  }

  p {
    color: #009FBF;
  }


}



@media screen and (max-width: 1300px) {

  .product_Data .tex_product_wrapper {
    margin: 0 20px 0 20px;
    justify-content: start;

  }
  .home_product {
    .StepUs_mobail{
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .StepUs{

        img{

        }
      }
    }

  }
  .product_Data .tex_product_wrapper .product_text {
   width: auto;
  }

  .OurStep_image {
    img {
      display: none;
    }

    .OurStep_text_1, .OurStep_text_2, .OurStep_text_3, .OurStep_text_4 {
      display: none;
    }
  }
  .Culture_wrapper .Culture_text {
    justify-content: center;

  }



  .product_text_box {
    margin-top: 50px;
  }
  .product_Data .tex_product_wrapper .product_image_spader img {
    display: none;
  }
  .Culture_wrapper .Culture_text {
    padding: 20px;
    flex-direction: column;
  }
  .Strategy_wrapper .Strategy_text {

    padding: 20px;
  }
      .OurStep_text {
        position: static;
        margin: 0 0 70px 0;
        width: 100vw;
        right: 0;
        height: auto;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 96px;
          height: 96px;
        }

        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          text-align: center;
          letter-spacing: 0.1px;

          p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 112.19%;

          }

        }
      }

}




@media screen  and (max-width: 1200px) and (max-width: 780px) and (max-width: 1000px){

  .nam_product{
    display: flex;
  }



  .Culture_wrapper {
    flex-wrap: wrap;
  }

  .Culture_wrapper .Culture_img {

    width: 100vw;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .Strategy_wrapper .Strategy_img {

    width: 100vw;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .Strategy_wrapper .Strategy_img img {
    width: 130px;
    height: 200px;
  }
  .Culture_wrapper .Culture_img img {
    width: 180px;
    height: 180px;
  }
  .product_Data .tex_product_wrapper {
    margin: 0;
  }
  .organization_product {
    margin-top: 0;
    margin-bottom: 80px;

    .organization_wrapper_product {
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 131.19%;
        text-align: center;
        color: #FFFFFF;
      }

      .sing_In {
        margin-left: 20%;
      }
    }


  }
  .product_Data {
    margin: 180px 20px 50px 20px;
    justify-content: center;

    .tex_product_wrapper {
      width: auto;

      .product_text {


        h2 {
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 119.19%;
          color: #FFFFFF;
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 148.19%;
          color: #FFFFFF;
        }

        p {
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 154.69%;
          color: #FFFFFF;
        }
      }

      .product_image img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-right: 0;
        width: 240px;
        height: 240px;
        margin-top: 60px;
        margin-left: 50px;
      }

      .product_image_spader {
        img {
          display: none;
        }
      }
    }

  }
  .product_text_box {
    margin: 20px;


    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      width: auto;


    }
  }

  .product_icon_boxes {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 20px 30px 20px;

    .product_boxes {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 350px;
      flex-wrap: wrap;
      margin: 0 50px 0 50px;
    }

    .product_box {
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #FFFFFF;
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }

  .product_icon_boxes {

     margin: 50px 0 30px 0;
  }
  .Culture_wrapper {

    padding: 0;
    flex-wrap: wrap;


    .Culture_text {
      order: 2;
      width: 100vw;
      flex-wrap: wrap;
      padding: 10px;
      margin-left: 20px;
      margin-right: 20px;
    }


  }
  .product_Data .tex_product_wrapper .product_text {
     width:auto;
  }
  .Strategy_wrapper {
    padding: 0;
    flex-wrap: wrap;

    .Strategy_text {
        width: 100vw;
         flex-wrap: wrap;
         padding: 10px;
         margin-left: 20px;
         margin-right: 20px;




    }
  }
  .OurStep_wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100vw;
    margin: 10px 0 70px 0;
    padding-right: 20px;
    padding-left: 20px;
    flex-direction: column;

    .Process_text {
      width: 100vw;
    }

    .Process_title {
      width: 100vw;
    }

  }
  .OurStep_wrapper {

    margin: 0 0 50px 0;

  }
}
