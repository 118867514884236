
.home_wrapper {
  width: 100vw;
  height: auto;

}

.home_bottom {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

}





.technology_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .technology_intro_section {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 427px;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    margin: 30px 0 30px 0;

  }

  .technology_tattle_intro {
    width: 585px;
    height: 162px;


    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 45px;
      color: #52515F;
    }

    hr {
      width: 69px;
      height: 3px;
      background: #009FBF;
      border-radius: 3px;
    }
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #52515F;

    }

  }

  .technology_tattle_intro_p {
    display: flex;
    width: 264.54px;
    height: 296.54px;


  }


}

.technology_icons_features {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  background: rgba(157, 215, 226, 0.09);

.features_boxes{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  flex-wrap: wrap;
 margin: 150px;
}
  .icons_box {
     display: flex;
     justify-content: space-between;
     align-items: center;
     flex-direction: column;
     width: 270px;
     height: 200px;

    img{
      width: 65px;
      height: 66px;
      margin-bottom: 30px;
    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.1px;
      color: #62626C;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      color: #62626C;
    }
  }
.icon_size{
  width: 60px;
 /* height: 60px;*/
  margin-bottom: 20px;
}
}

.home_video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .video_tattle {

    h2 {
      margin-top: 100px;
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.005em;
      color: #009FBF;
      margin-bottom: 100px;
    }
  }

  .react-player {

    .react-player__preview {
      border-radius: 100px 0 0 0;
      background-size: cover;
      background-repeat: no-repeat;


    }

    .sing_in .Button-inner {

      display: flex;
      justify-content: center;
      width: auto;
      height: 45px;
      margin-left: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      align-items: center;
      color: #FFFFFF;

    }

  }

}
.bgnhmj{
  display: flex;
  align-items: center;
  justify-content: center;

}
.home_bottom_strat {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: rgba(0, 159, 191, 0.03);
  align-items: center;
  justify-content: center;

  .home_strategic {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 478px;
    width: 100vw;
  }

  .home_tattle {
    width: 478px;
    margin-right:30px;

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 38px;
      letter-spacing: 0.005em;
      color: #52515F;
    }

    hr {
      width: 69px;
      height: 3px;
      left: 232px;
      top: 2824px;
      background: #009FBF;
      border-radius: 3px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #62626C;

    }


  }

  .home_image_tattle {
    width: 313px;
    height: 315px;
    margin-left: 30px;

  }
}

.home_tattle_cover {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 438px;
  margin-top: 50px;

  .home_tattle_cov {
    display: flex;
    width: 789px;
    height: 177px;

    img {
      width: 74.49px;
      height: 71px;
      margin-top: -40px;

      margin-right: -30px;


    }

    h3 {
      font-style: italic;
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #009FBF;
      opacity: 0.75;

      svg{
        color: #C4C4C4;
        margin-right:10px;
        margin-top: -30px;
      }
    }
  }
}





.organization {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 279px;
  background: #009FBF;
  border-radius: 20px;
  margin-bottom: 133px;
  .Contact_button{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .organization_wrapper {
    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
      color: #FFFFFF;
    }

    .sing_In {
      width: auto;
      height: 45px;
      padding: 15px;
      display: flex;
      justify-content: center;
      border: 2px solid transparent;
      border-radius: 99em;

      margin-top: 24px;
      bottom: 32.77%;
      background: #FFFFFF;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);



      .Button-Inner {
        font-weight: 400;
        font-size: 16px;
        line-height: 86.5%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #037E97;

        &:focus {
          border-color: #fff;
          box-shadow: 0 0 0 2px #009fbf;
        }

        &:hover {
          filter: brightness(1.1);
          color: #272b31;
        }

        &:active {
          filter: brightness(0.9);
        }
      }
    }
  }

}


.technology_text_laopdk {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 680px;
  height: 210px;
  margin-bottom: 60px;

  h2 {

    margin-bottom: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 119.19%;
    color: #000000;
    opacity: 0.75;
  }

  p {
    text-align: center;
  }


}

.technology_link_ipsum {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .technology_link_progress {
    width: 600px;
    margin-right: 250px;

    img {
      margin-bottom: 20px;
    }

  }

  .technology_link_ipsum_text {
    width: 500px;
    margin-left: 100px;
    margin-bottom: 70px;

    h2 {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      opacity: 0.75;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 70px;
      color: #000000;
      opacity: 0.75;
    }
  }

}

.technology_link_ipsum_com {

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .technology_comp {
    display: flex;
    flex-direction: column;


    .technology_link_ipsum_text_comp {
      width: 426px;
      margin-right: 100px;


      h2 {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #000000;
        opacity: 0.75;
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 60px;
        color: #000000;
        opacity: 0.75;
      }

    }

  }

  .technology_link_comp {
    width: 600px;
    margin-left: 250px;

    img {
      margin-bottom: 60px;
    }
  }
}


.technology_ready {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 378px;


  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 33px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #52515F;

  }

  .sing_in .Button-inner {
    display: flex;
    justify-content: center;
    width: auto;
    height: 45px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }
}


.main__carusel{
  display: flex;
}
.main_carusel_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  width: 100vw;
  margin: 100px 0 80px 0;

  .main_text_carusel {
    display: flex;
    margin-left: 150px;
    align-items: center;
    justify-content: start;


    hr {
      width: 55px;
      height: 3px;
      background: #084f5d;
      border-radius: 3px;
      margin-top: 10px;

    }


    h2 {
      width: 270px;
      height: 200px;
      font-style: italic;
      font-weight: 500;
      font-size: 33px;
      line-height: 40px;
      color: #037E97;


    }

  }

.main_wrapper_carusel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 400px;



    .carusel_tatle {
      width: 360px;
      height: 360px;
      border: 1.5px solid #009FBF;
      border-radius: 20px;
      padding: 30px;
.carusel_p{
  p {
    width: 300px;
    height: 200px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #52515F;
  }
}


      .carusel_img {
        display: flex;
        flex-direction: row;
        align-items: start;
        margin-top: 70px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 100px;
          margin-right: 30px;
        }

        .carusel_text_client {
          width: 221px;
          height: 70px;

          h5 {
            font-style: normal;
            font-weight: 300;
            font-size: 19px;
            line-height: 30px;
            color: #52515F;

          }

          h6 {

            font-style:normal;
            font-weight:400;
            font-size: 12px;
            line-height: 20px;

            color: #52515F;
          }
        }


      }
    }

  }
}
@media screen and  (max-width: 414px){
  .technology_icons_features {
    justify-content: center;
    width: 100vw;
    height: auto;
    padding: 0;

    .features_boxes {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      flex-wrap: wrap;
      padding: 20px 0 20px 0;
    }

    .icons_box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 200px;
      height: auto;
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 30px;

      img {
        width: 65px;
        height: 66px;
        margin-bottom: 30px;
      }

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52515F;
        margin-bottom: 11px;
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #52515F;

      }
    }
  }}


@media screen and (max-width: 1400px){
  .technology_icons_features .features_boxes {

    justify-content: center;

    margin: 130px 90px 130px 90px;
  }
  .main_carusel_wrapper .main_text_carusel {

    margin-left: 90px;

  }
}
@media screen and (max-width: 1250px) {
  .technology_icons_features .features_boxes {
    justify-content: center;
    margin: 70px 20px 70px 20px;


  }
  .technology_icons_features .icons_box {
    margin-top: 80px;
  }
  .main_carusel_wrapper .main_text_carusel {

    margin-left:80px;

  }
}
@media screen and (max-width: 780px) and (max-width: 1000px) and (max-width: 1200px){



  .technology_icons_features .features_boxes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    flex-wrap: wrap;

  }
  .technology_icons_features .icons_box {
    margin-top: 80px;
  }
  .home_bottom_strat .home_image_tattle {
    margin-top: 50px;
    width: 180px;
    height: 180px;
    margin-bottom: 50px;
  }
  .alice-carousel__prev-btn {
    margin: 20px 0 20px 0;

  }
  .technology_wrapper .technology_tattle_intro_p {
   display: none;
  }
  .technology_wrapper .technology_tattle_intro {
    width: auto;
    height: auto;
    margin: 46px 40px 46px 20px;

  }
  .technology_wrapper .technology_intro_section {
    display: flex;
    width: 100vw;
    height: auto;
    justify-content: space-between;
    flex-direction: row;
    h1{
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 38px;
      color: #52515F;

    }
    p{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #62626C;
    }
  }
  .technology_icons_features {
    justify-content: center;
    width: 100vw;
    height: auto;
    padding: 0;
    .features_boxes{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      flex-wrap: wrap;
      padding: 20px 0 20px 0;
    }
    .icons_box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100vw;
      height: auto;
      flex-wrap: wrap;
      margin: 40px 20px 40px 20px;


      img{
        width: 65px;
        height: 66px;
        margin-bottom: 30px;
      }

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #52515F;
        margin-bottom: 11px;
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #52515F;

      }
    }

    .icons_img {
      width: 76px;
      height: 78px;
    }
  }
  .home_video {
    .video_tattle {

      h2{
        margin-top: 46px;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #009FBF;
        margin-bottom: 40px;
      }
    }


    .technology_ready {
      height: auto;
      padding-top: 85px;
      padding-bottom: 50px;

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        color: #52515F;
      }

      .sing_in .Button-inner {

        align-items: center;

      }
    }

  }
  .home_tattle_cover {
   height: auto;
    margin: 95px 20px 105px 20px;

    .home_tattle_cov {

      img {
        width: 48px;
        height: 48px;
        margin-top: -30px;
        margin-right: -40px;
      }

      h3 {
        font-style: italic;
        font-weight: 300;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #009FBF;
        opacity: 0.75;
      }
    }
  }

  .technology_icons_features .icons_box {

    margin: 20px;
  }
  .technology_wrapper {
    h2 {
      font-weight: 500;
      font-size: 30px;
      line-height: 119.19%;
    }


    .technology_icons {
      flex-wrap: wrap;
    }
  }
  .technology_image_link {
    flex-wrap: wrap;
    margin-top: 5%;
    margin-bottom: 15%;

    .technology_image {
      margin-bottom: 20%;
    }

    .technology_link {
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 47px;
        margin-bottom: 8%;

      }

      p {
        font-size: 22px;
        line-height: 26px;


      }

    }
  }
  .organization {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 300px;
    background: #009FBF;
    border-radius: 20px;
    margin-bottom: 50px;

    .organization_wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #FFFFFF;
      }
      .sing_In {
        margin-left: 0;
      }
    }

  }
  .home_bottom_strat {
    padding: 0;
    .home_strategic {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content:center;
      height: auto;
      width: 100vh;
      padding: 40px 20px 0 20px;
    }
    .home_tattle {
      width: 100vh;

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 38px;
        letter-spacing: 0.005em;
        color: #52515F;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #62626C;
      }

    }
  }
  .main_carusel_wrapper {
    display: flex;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;


    .main_text_carusel {
      display: flex;
      padding-left: 0;
      padding-right: 0;
      margin-left: 25px;

      justify-content: start;

hr{
  width: 50px;


}


      h2 {
        font-style: italic;
        font-weight: 300;
        font-size: 30px;
        line-height: 30px;
        color: #009FBF;
        width: 270px;


      }

    }

    .home_tattle .aos-init .aos-animate{
      animation: none;
    }


  }


}
.main_carusel_wrapper .main_wrapper_carusel .carusel_tatle {
  height: auto;

}