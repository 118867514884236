$head_color: #0e161d;


.header {
  display: flex;
  position: fixed;
  z-index: 98;
  background: rgba(32, 33, 38, 0);
  font-family: 'Roboto', sans-serif;
  width: 100vw;

}


.colorChange {
  background: #0e161d;
  z-index: 98;

  select {
    color: white;
    background-color: rgba(194, 204, 213, 0);
  }

}


.header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 39px;
  position: fixed;
  top: 0;
  padding-left: 135px;
  padding-right: 105px;
  transition: 0.6s;

  &:hover {
    color: #c7b4b4;
    cursor: pointer;
  }
}

.header_icons_left {
  display: flex;
  align-items: center;

  a {
    &::before {
      content: "";
      top: 31.5px;
      left: 13px;
      position: absolute;
      width: 100%;
      height: 1.5px;
      background-color: #009fbf;
      transition: 0.2s;
      transform: scaleX(0);
    }

    &:hover:before {
      transform: scaleX(1);
    }

  }


}


.singnup {
  color: #fff;
  text-decoration: none;
  width: 15px;
  height: 15px;
  margin-left: 24px;
  transition: opacity .2s ease-in-out;


  &:hover {
    color: #009fbf;
    cursor: pointer;
  }

}

.header_icons_right {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-left: 10px;

  &:hover {
    color: #009fbf;
    cursor: pointer;
  }

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #FFFFFF;
    margin-left: 10px;

    &:hover {
      color: #009fbf;
      cursor: pointer;
    }

    &::before {
      content: "";
      top: 31.5px;
      margin-right: 15px;
      position: absolute;
      width: 100%;
      height: 1.5px;
      background-color: #009fbf;
      transition: 0.2s;
      transform: scaleX(0);
    }

    &:hover:before {
      transform: scaleX(1);

    }
  }

  .btn {

    font-weight: 300;
    font-size: 16px;
    line-height: 160%;

    display: inline-block;

    color: white;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.75rem 0.75rem;

    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

}

.btn-secondary {
  color: #fff;
  background-color: rgba(32, 33, 38, 0);
  border-color: rgba(32, 33, 38, 0);

  &:focus {
    background-color: rgba(32, 33, 38, 0);
    color: #fff;
    border-color: rgba(32, 33, 38, 0);
    box-shadow: none;
  }

}

.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: rgba(32, 33, 38, 0);
  border-color: #009fbf;
}

.dropdown-menu {
  position: relative;
  display: none;
  min-width: 100px;
  padding: 0.5rem 0;
  margin: 0;
  z-index: 1000;
  text-align: left;
  list-style: none;
  background-color:#0e161d;
  background-clip: padding-box;
  border: 1px solid #0e161d;
  border-radius: 0.25rem;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
/*  color: #FFFFFF;*/

}

.dropdown-item {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  color:white;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;

  border: 0;
  z-index: 1000;

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #FFFFFF;
  }

  :hover {
    background-color: #009FBF;
  }

}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #009FBF;
}

.logotip {
  display: none;
}

.header_bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100vw;
  margin-top: 41px;
  height: 86px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;

  padding-left: 150px;
  padding-right: 140px;

  .ghost-bar {
    height: 2px;
    background: hsla(0, 0%, 100%, .1);;
    position: absolute;
    left: 0;
    top: 39px;
    right: 0;

  }
}

.dropdown-menu.show {
  display: block;
}

.display_desktop {

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100vw;



}

.header_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;


  span {
    color: white;
    font-size: 22px;
    letter-spacing: .7rem;


    &:hover {
      color: #009fbf;
    }
  }

  li {
    color: white;
    font-size: 13px;
    letter-spacing: .3rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;

    &:hover {
      color: #009fbf;
    }
  }

}


.header_right {


  span {
    display: inline;
    position: relative;
    margin-left: 20px;
    color: white;
    letter-spacing: 2px;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;

  }

  span {


    &::before {
      content: "";
      bottom: 55px;
      left: 0;
      position: absolute;
      width: 100%;
      height: 1.5px;
      background-color: #009fbf;
      -webkit-transition: 0.2s;
      -o-transition: 0.2s;
      transition: 0.2s;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
    }

    &:hover:before {
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);

    }

  }

  .nav {

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      margin-right: 35px;

      svg {
        margin-left: 7px;
        opacity: 0.5;
        font-size: 13px;
      }

      &:hover {
        color: #009fbf;

      }

    }


  }
}

.logo {
  height: 32px;
  width: 180px;

  img {
    cursor: pointer;

  }


}

.header__logo {
  width: 60px;
  height: 40px;
}

.angle {
  color: white;
  margin-left: 2px;
  height: 0.7em;

}

.login_button {

  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;

}

.sing_in {

  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  display: block;
  font: inherit;
  height: auto;
  line-height: 1;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;

  .Button-inner {

    -webkit-box-align: center;

    -ms-flex-align: center;

    align-items: center;
    background: #009fbf;
    border: 2px solid transparent;
    border-radius: 99em;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 400;
    height: 35px;
    padding: 15px 25px 15px 25px;
    position: relative;
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
    width: 100%;
    box-shadow: 10px 13px 24px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 13px 24px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 13px 24px -8px rgba(0, 0, 0, 0.75);

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      color: #FFFFFF;

    }

    &:focus {
      border-color: #fff;
      -webkit-box-shadow: 0 0 0 2px #009fbf;
      box-shadow: 0 0 0 2px #009fbf;
    }

    &:hover {
      -webkit-filter: brightness(1.1);
      filter: brightness(1.1);
      color: #272b31;
    }

    &:active {
      -webkit-filter: brightness(0.9);
      filter: brightness(0.9);
    }
  }

}


.dropdown-content-lang {
  position: absolute;
  color: black;
  background-color: rgba(100, 90, 90, 0.5);
  margin-left: 115px;
  width: 110px;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 500;

  .linc_hover {
    padding: 5px 0 20px 5px;
    text-decoration: none;
    display: block;
    z-index: 300;
    font-weight: normal;


    &:hover {
      background-color: #009fbf;


    }

    .linc_lang {
      margin: 20px;
    }
  }

  span {
    color: white;
    font-size: 14px;
  }
}


.dropdown-content {
  width: 200px;
  display: none;
  position: absolute;
  min-width: 130px;
  height: auto;
  z-index: 250;
  border-radius: 10px;


  .dropdawn_menu {


    a {

      padding: 12px 0 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 13.5px;
      line-height: 160%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #FFFFFF;

      &:hover {
        color: #009fbf;
      }
    }
  }
}


.dropdown-content {

  .linc_hover {
    padding: 0;
    text-decoration: none;
    display: block;
    z-index: 300;
    font-weight: normal;


  }

  span {
    color: white;
    font-size: 14px;
  }
}

.szh-menu-container {

  position: relative;
  width: 200px;
  height: 0;

  .szh-menu__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    position: relative;
    padding: 5px 0 5px 12px;
  }

  .szh-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: -moz-max-content;
    width: max-content;
    position: absolute;
    z-index: 100;
    border: 1px solid #0e161d;

    background: #0e161d;
    -webkit-box-shadow: 0 3px 2px #0e161d;
    box-shadow: 0 3px 2px #0e161d;
  }

  .szh-menu__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 5px 0 5px 10px;

    &:hover {
      background: #0e161d;
    }
  }

}

.dropdown-content {
  display: block;

}


.mobile-menu-drowen {
  display: block;
  color: white;
  position: absolute;
  outline: none;
  z-index: 50;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 5%;

}

.mobile-menu-drowen_close {
  display: block;
  color: white;
  position: absolute;
  outline: none;
  z-index: 50;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  right: 5%;

}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 300;

}


.dropdown .-list {
  display: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 100%;


}

.dropdown .-list a {
  display: block;
  text-decoration: none;
  color: inherit;

}

.dropdown .-list a:hover {
  color:#0e161d;
}

.dropdown:hover .-list {
  display: block;

}

.dropdown:hover .-hover::after {
  content: ' \02C5';
  color: white;
  font-size: 100%;
  margin-right: 50px;

}

.-list {
  span {
    color: white;
  }
}

.display_mobile {
  display: none;
}


@media screen and (max-width: 1400px) {
  .display_desktop {
    display: none;
  }
  .header_left {
    margin-right: 14%;
  }
  .login_button {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    width: 100vw;


  }
  .header {
    background-color: #0e161d;
  }

  .display_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    bottom: 17px;
    color: white;
    width: 80px;

    .mobile_navlinc {
      top: 39px;
      left: 0;
      border-top: 2px solid #009fbf;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: start;
      background: #0e161d;
      width: 100vw;
      height: 100vh;
      margin-left: 0;
      position: absolute;
      padding-right: 0;
      z-index: 3;
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
    }

    .logo {
      z-index: 105;
      width: 150px;

    }

    .logotip {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 7% 0 15% 0;
      width: 100vw;

      img {
        width: 150px;
      }

    }

    .dropdown {
      width: 100vw;
      padding: 20px 0 5px 20px;
      margin-top: 10px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
        color: #FFFFFF;

      }

      .dropdown:hover .-hover::after {
        content: ' \02C5';
        color: white;
        font-size: 60px;
        margin-right: 56px;

      }


      .-hover {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100vw;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 30px;
      }

      .-list {
        z-index: 250;
      }
    }
  }
  .dropdown .-hover::after {
    font-size: 30px;
  }
  .logo {

    width: 150px;
  }

  .header_icons_right {

    margin-right: 0;

    span {
      font-size: 12px;
      margin-left: 0;

      .singnup {
        margin-right: 0;

      }
    }
  }

  .header_top {
    padding-left: 0;
    padding-right: 1%;

  }
  .header_left {
    margin-right: 0;


  }
  .header_bottom {
    padding-right: 0;
    padding-left: 0;
  }
  .header_right {
    display: none;
  }
  .sing_in {


    .Button-inner {
      border: 0;

    }
  }
  .nav-links-mobile {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    list-style: none;
    background: #0e161d;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    top: 0;
    width: 100%;
    height: auto;
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    z-index: 300;


  }

  .header_wrapper {
    .login_back {

      margin: 0;
    }

    .mobile-menu-icon_close {
      display: block;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      right: 5%;
      bottom: 20%;
      color: white;
      position: absolute;
      outline: none;
      z-index: 50;
      cursor: pointer;

    }
  }
  .drowe_link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;

    .linc_hover {
      margin-top: 20px;
      margin-left: 20px;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 160%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      color: #009fbf;


      &:hover {
        color: white;

      }


    }
  }
  .header_icons_right {

    margin-left: 0;
  }
  .singnup {
    width: 13px;
    height: 13px;
    margin-left: 20px;


  }
  .header_bottom {
    padding-left: 2%;
    padding-right: 2%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

  }
  .header_icons_right .btn {
    font-weight: 300;
    font-size: 12px;
    font-style: normal;
    line-height: 160%;

  }
}

@media screen and (max-width: 900px) {


  .header_bottom {
    margin-left: 0;
    margin-right: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

  }

  .display_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display:flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    bottom: 17px;
    color: white;
    margin-left: 40px;
  }

}






