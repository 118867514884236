.policy_wrapper{
   display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  flex-wrap: wrap;
  flex-direction: column;
  background: rgb(32, 33, 38);
  padding: 12rem 0.5rem 8rem 0.5rem;
  z-index: -1;
p{
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 148.69%;
  letter-spacing: 0.07em;
  color: #FFFFFF;
}

}

.policy{
  width: 1200px;
  h1{
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.5em;
    color: #C4C4C4;
  }
  h2{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.5em;
    color: #C4C4C4;

  }
  p{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;

    color: #C4C4C4;
    line-height: 1.5em;
  }

  span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5em;
    color: #C4C4C4;

  }
}
.Purposes_for{
  display: flex;
  flex-wrap: wrap;
  width: 1200px;

  p,a {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 146.69%;
    color: #C4C4C4;

  }

}
.Purposes_left{
   display: flex;
   justify-content: space-between;
  flex-wrap: wrap;

  .Purposes_left_1{
    width: 400px;
    border: 0.5px solid white;
    padding: 10px;
    flex-wrap: wrap;
  }
  .Purposes_rate_1{
    width: 800px;
    border: 0.5px solid white;
    padding: 10px;
    flex-wrap: wrap;
  }
  }
.Purposes_rate{
  display: flex;
  flex-direction: column;
  width:600px;
}

@media screen and (max-width: 820px)  and (max-width: 912px) and (max-width: 1024px) {
  .Purposes_left{
    justify-content: center;

    .Purposes_left_1{
      width: 200px;

      padding: 5px;
    }
    .Purposes_rate_1{
      width: 500px;

      padding: 5px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .Purposes_left{
    justify-content: center;

    .Purposes_left_1{
      width: 350px;

      padding: 5px;
    }
    .Purposes_rate_1{
      width: 600px;

      padding: 5px;
    }
  }
}
@media screen and (max-width: 780px) {

  .policy {

    h1 {
      font-weight: 300;
      font-size: 30px;
    }

  }

  .CookieConsent{
    height: 40%;
    padding: 10px;

    p {
      font-weight: 500;
      font-size: 10px;
      line-height: 146.69%;
    }
  }
  .Purposes_for{

    width: 100vw;


  }
  .Purposes_left{
    justify-content: center;

    .Purposes_left_1{
      width: 100px;

      padding: 5px;
    }
    .Purposes_rate_1{
      width: 230px;

      padding: 5px;
    }
  }
}