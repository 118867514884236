


.contact_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height:auto;
  flex-wrap: wrap;
  background-image: url("../../components/carousel/imageCarousel/img_7.png");
  background-size: cover;
  background-repeat: no-repeat;


  }


.contact_form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

h2{
  color:#009fbf ;
}


  .sing_in {
    margin-left: 0;
    width: 400px;
    height: 38px;

  }




}
.contact_colum{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  flex-direction:column;
  margin-bottom: 100px;

  h2{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
    text-align: center;
    color: #009FBF;

  }
  p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 152%;
    text-align: center;

    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;


  }

}


.row  {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right:0;
  padding-left:0;
  margin-bottom:20px;
.errors{
  color: red;
  font-weight: 30;
  font-size: 20px;
  line-height: 134.69%;
  letter-spacing: 0.03em;
}


  select{
    height: 36px;
    left: 585px;
    top: 289px;
    background: #F3F3F3;

    color: #4f5050;
    size: 30px ;
    border: 1px solid #D8D8D9;
    border-radius: 6px;
  }


}
input[type=text], [type=tel]{

  margin-top: 20px;
  border: 1px;

  height: 36px;
  left: 585px;
  top: 289px;
  background: #F3F3F3;
  border-radius: 6px;
}
textarea[type=submit]{

  height: 99px;
  left: 585px;
  top: 473px;
  background: #F3F3F3;
  border-radius: 6px;
}

.form-control{
  margin-top: 20px;
  border: 1px;

  height: 36px;
  left: 585px;
  top: 289px;
  background: #F3F3F3;
  border-radius: 6px;
  option{
    color: #636464;
  }
}
.formname input[type=checkbox] {
  display:none;
}
.formname input[type=checkbox] + label {
  display: block;
  position: relative;
  margin: 12px 0 12px 30px;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
}
.formname input[type=checkbox] + label:hover {
  color:#009fbf;
}
.formname input[type=checkbox] + label:before {
  position: absolute;
  content: '';
  width: 22px;
  height: 22px;
  line-height: 22px;
  left: -30px;
  border: 1px solid #808080;
  background-color: white;

}
.formname input[type=checkbox]:checked + label:before{
  content: '\2714';
  color:#009fbf;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  border: 1px solid #337AB7;
}
.formname input[type=submit] {
  display: block;
  cursor: pointer;
  font-size: 12px;
  padding: 10px 30px;
  border: 1px solid #275F8B;
  background: #337AB7;
  color: #FFF;
  text-transform: uppercase;
  transition: .4s all;
  font-weight: bold;
}

.formname input[disabled] {
  opacity: .6;
  cursor: not-allowed;

}
.back{

  padding-top: 20px;

  p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #00B0D3;
    &:hover{
      cursor: pointer;
      color: white;
    }
  }
}

.flo_Logo{
  width: 150px;
  height: 35px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.Privacy_As{
  margin-bottom: 20px;
 text-align: center;
}

.form-tattle{
  width: 750px;

}
@media screen and (max-width: 780px) and (max-width: 1000px) and (max-width: 1200px)  {
  .contact_wrapper_contactAs{
    height: auto;
  }

  .contact_wrapper, .contact_wrapper_contactAs {

    .login_back{
      margin-left: 10px;
    }
  }
  .form-tattle{
    width:100vh;
  }
  .contact_colum{
    p{
      margin-bottom: 20px;

    }
  }

  button{
    margin-right: 0;
  }
  .back{
    padding-top: 30px;
    padding-bottom: 50px;
    height:32px;

  }
}
