.carousel-indicators [data-bs-target] {
  width: 7px;
  height: 7px;
  margin-right: 12px;
  background: #75777c;;
  box-sizing: content-box;
  flex: 0 1 auto;
  padding: 2px;
  border-radius: 100%;
  margin-left: 3px;
  text-indent: -999px px;
  cursor: pointer;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;

}

.carousel-control-prev {
  display: none;
}

.carousel-control-next-icon {
  display: none;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: #ded2d2;
}

.home_banner {
  background: #070e01;

}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  margin-bottom: 1rem;
  list-style: none;
}

.carousel_wrapper, .carousel_wrapper_2, .carousel_wrapper_3,.carousel_wrapper_4{
  align-items: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  background-image: url("./imageCarousel/img_7.png");
  background-size: cover;
  background-repeat: no-repeat;
  .carousel_wrapper{
    box-shadow: inset 0 0 0 50vw rgba(32, 37, 42, 0.79);
  }
  .carousel_wrapper_2,{
    box-shadow: inset 0 0 0 50vw rgba(203, 179, 25, 0.1);
  }

  .carousel_wrapper_3{
    box-shadow: inset 0 0 0 50vw rgba(10, 222, 198, 0.1);
  }
  .carousel_wrapper_4{
    box-shadow: inset 0 0 0 50vw rgb(241, 238, 238);
  }
}


.button_slader{
button {
width: 147px;
height: 45px;
left: 8.33%;
right: 81.46%;
border: 2px solid transparent;
margin-top: 20px;
background:#8BA9C1;
border-radius: 25px;

&:hover {
filter: brightness(1.1);
color: #272b31;
}

&:active {
filter: brightness(0.9);
}

.button_data {
background: rgba(0, 159, 191, 0.67);

&:focus {
  box-shadow: 0 0 0 2px #009fbf;
}
}

span {
color: #030A17;
}
}
}
.carousel-caption {
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
position: relative;
margin-right:33%;
margin-left: -6%;

margin-top: 5%;
padding-top:3%;
padding-bottom: 0;
color: #fff;
text-align: center;
width: 100vw;

}



.block_carusel{
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;
width: 290px;
height: 290px;

.bloc-img {
display: flex;
justify-content: center;
align-items: center;
width: 270px;
height:270px;
margin-top: 20px;
.slade {
width: 304.59px;
height: 307.61px;

}

.slade_2 {
width: 213px;
height: 364px;
}

.slade_3 {
width: 276px;
height: 297px;
}

.slade_4 {
width: 297px;
height: 372px;
}
}

}


.carousel_text {
width: 730px;
display: flex;
flex-direction: column;
align-items: start;
flex-wrap: wrap;

h2 {
font-style: normal;
font-weight: 500;
font-size: 40px;
line-height: 45px;
display: flex;
align-items: center;
color: #8BA9C1;

}

p {
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
color: white;
}

.sing_in {
margin-left: 0;
}
}



[data-aos="example-anim2"] {
background: #009FBF;
transition-property: background;

&.aos-animate {
background: #009FBF;
}
}

[data-aos="example-anim4"] {
background: #009FBF;
transition-property: background;

&.aos-animate {
background:rgba(49, 128, 71, 0.67);
}
}

[data-aos="example-anim5"] {
background: #009FBF;
transition-property: background;

&.aos-animate {
background: #A17DDC;
}
}

[data-aos="example-anim3"] {
transform: rotate(360deg);
opacity: 0;
transition-property: transform, opacity;

&.aos-animate {
transform: rotate(0);
opacity: 1;
}
}
@media screen and (max-width: 1400px){
.carousel-caption {
align-items: start;
justify-content: start;
margin-right: 0;
margin-left: 0;
margin-top: 5%;
padding-top: 3%;

}
.block_carusel .bloc-img , .slade, .slade_2 .slade_3,.slade_4 {
display: none;
}
}


@media screen and (max-width: 780px) and (max-width: 1000px) and (max-width: 1200px){

.carousel-caption {
display: flex;
flex-wrap: wrap;
right: 0;
left: 2%;
margin: 0 20px 0 0;

}
.block_carusel .bloc-img , .slade, .slade_2 .slade_3,.slade_4 {
display: none;
}
.carousel_wrapper {
justify-content: start;

}



.sing_in {
padding-top: 0;
margin-left: 0;
}
.carousel-caption {
position: absolute;
text-align: start;

}


.carousel_text {

display: flex;
align-items: start;
flex-wrap: wrap;
margin-top: 50px;
margin-right: 20px;


h2 {
font-style: normal;
font-weight: 500;
font-size: 25px;
line-height: 38px;
display: flex;
align-items: start;
color: #B2D3ED;

}

p {
margin-bottom: 30px;
}

}

.carousel-indicators {

padding-bottom: 0;

}

.bloc-img {
display: none;
}
[data-aos] {
pointer-events: auto !important;
}

html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
opacity: 1 !important;
}

html:not(.no-js) [data-aos=fade-up] {
transform: none !important;
}

}
