.why_flowle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-image: url("./imageWhyFlowle/img_14.png");
  box-shadow: inset 0 0 0 50vw rgba(2, 10, 16, 0.66);
  background-size: cover;
  background-repeat: no-repeat;
}
.Why_flowle_end{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.GetData_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  height: auto;
}

.get_Data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100vh;
  flex-wrap: wrap;
  margin: 250px 250px 100px 150px;

  .get_Data_text {
    width: 640px;


    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      color: #FFFFFF;
    }

    h4 {
      margin-top: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 140.19%;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    hr {
      width: 69px;
      height: 3px;
      background: #459692;
      border-radius: 3px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140.69%;
      color: #FFFFFF;

    }
  }

  .getData_image {
    display: flex;
    align-items: center;
    width: 290px;
    height: 290px;
    justify-content: center;

    img {

      width: 280px;
      height: 280px;
    }
  }

}


.liegen_Probleme {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: auto;


  .problems {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;



    h2{
      font-weight: 500;
      font-size: 30px;
      line-height: 160%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;

    }

    p {

      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 141.19%;
      text-align: center;
      color: #FFFFFF;
      margin-bottom: 50px;
    }
  }

  .wrapper_problems {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;

  }

  .problems_box_wrapper {
    flex-wrap: wrap;
    width: 1200px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;

  }

  .problems_box {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 340px;
    height: 300px;
    margin-bottom: 70px;
    .Toxic{
      width: 170px;
      display: flex;
      align-items: center;

      justify-content:center;




    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 119.69%;
      text-align: center;
      color: #FFFFFF;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 141.19%;
      text-align: center;
      color: #FFFFFF;
    }

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;



    }
  }


}


.Help {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  background:  #0C1B26;


  .Help_text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 650px;
    height: 300px;
    flex-direction: column;
    margin: 90px 0 0 140px;

    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
    }

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 38px;
      letter-spacing: 0.005em;
      color: #459692;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;;
      text-align: center;
    }
  }

  img {
    width: 325px;
    height: 570.28px;
    position: relative;
    top: -25px;

  }

}

.box_wrapper_help {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 900px;
  justify-content: space-between;
  margin: -100px 200px 70px 330px;



  .box_help {
    display: flex;
    flex-direction: column;
    width: 278px;
    height: 315px;
    background: rgba(244, 247, 249, 0.13);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 50px 20px 20px 20px;

    img {
      width: 60px;
      height: 60px;
    }

    .nam {
      img {
        width: 16px;
        height: 28px;
        margin-top: -80px;
        margin-left: 22px;
      }
    }
.Lower_Costs{
  width: 278px;

}
    h3 {
      margin-top: -25px;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      display: flex;
      align-items: center;
      font-feature-settings: 'salt' on, 'liga' off;
      color: white;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 19px;
      color: white;
    }
  }


}

.What_Apart {
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: space-between;
  flex-wrap: wrap;

  .Apart_text {
    display: flex;
    align-items: start;
    width: 470px;
    flex-direction: column;
    margin-top: -60px;

  }

  h2 {

    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    color:#459692;

  }

  hr {
    width: 69px;
    height: 2.91px;
    background: #459692;
    border-radius: 3px;
  }

  h3 {
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #FFFFFF;

  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    color: #FFFFFF;

  }

  .comp_help {

    img {
      width: 603px;
      height: 468.21px;
      margin-top: 150px;
      margin-bottom: 150px;
    }

  }

  .SpiderLeft {
    img {
      width: 250px;
      height: 652.76px;
      margin-left: 75px;
    }
  }
}

.Unique_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;
  height: auto;
  flex-direction: row;
  box-shadow: inset 0 0 0 50vw rgba(7, 16, 24, 0.8);
  background-image: url("../whyFlowle/imageWhyFlowle/img_15.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  .Unique_boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 90px 110px 100px 110px;
  }

  .Unique_box {

    display: flex;
    align-items: start;
    width: 400px;
    height: 300px;
    flex-direction: column;
    border-color:white;
    border-width: thin;
    border-style: dotted;
    padding: 60px 50px 30px 50px;

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 30px;
      letter-spacing: 0.1px;
      color: white;
      opacity: 0.75;
      margin-bottom: 5px;

    }

    p {

      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: white;

    }
  }
}


@media screen and (min-width: 1750px){
  .Help .Help_text {
    width: 1200px;
    margin: 120px;
  }
  .get_Data {

    margin: 250px 250px 50px 150px;

  }
  .box_wrapper_help {

    width: 100vw;
    justify-content: space-around;
    margin: 0;
  }
  .Unique_wrapper .Unique_box {
    width: 500px;
    padding: 50px;
  }
}
@media screen and (min-width: 1700px){
  .Help .Help_text {
    width: 1000px;
    margin: 120px;
  }
  .box_wrapper_help {

    width: 100vw;
    justify-content: space-around;
    margin: 0;
  }
  .Unique_wrapper .Unique_box {
    width: 500px;
    padding: 50px;
  }
}
@media screen and (max-width: 1450px){

  .GetData_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 60vh;
  }
  .Help img {
    width: 325px;
    height: 570.28px;
    position: relative;
    top: -25px;
  }
  .get_Data {

    margin: 50px 20px 0 20px;

  }
}
@media screen and (max-width: 1400px){
  .Help{
    .spader_non{
      display: none;
      img{

      } }
  }

  .Help .Help_text {
    width: 100vw;
    height: auto;
    margin: 90px 150px 0 140px;
  }

  .box_wrapper_help {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    justify-content: space-around;
    margin: 90px 0 0 0;
  }
  .What_Apart .SpiderLeft  {
    display: none;
  }
  .What_Apart .Apart_text {

    margin-right: 120px;
  }
}

@media screen and (max-width: 1200px){
  .What_Apart .Apart_text {
    margin-right: 0;
    margin-bottom: 40px;
  }

  .What_Apart .comp_help{
    img {
      width: 400px;
      height:350px;

    }
  }
}
@media screen and (max-width: 860px){
  .What_Apart .Apart_text {

    width: 100vw;
    margin-left: 20px;

  }
  .box_wrapper_help .box_help {
    margin-bottom: 50px;
  }

}
@media screen and (max-width: 760px){
  .box_wrapper_help {

    justify-content: space-around;
    margin: 40px 20px 0 20px;
  }
}

@media screen and (max-width: 780px) and (max-width: 1000px) and (max-width: 1200px) {
  .GetData_wrapper {
     width: 100vw;
     height: auto;

  }

  .get_Data {
    margin: 180px 20px 50px 20px;
  }
  .get_Data .get_Data_text {
    width: auto;
    height: auto;

    h2 {
      font-weight: 500;
      font-size: 26px;
      line-height: 119%;
      display: flex;
      align-items: center;
      padding-right: 70px;

    }
  }
  .get_Data .getData_image img {
    width: 250px;
    height: 250px;
    margin: 20px 0 0 60px;
  }

  .liegen_Probleme .problems {
    padding-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;

    p {
      text-align: start;
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      line-height: 128.19%;

      margin: 0;
    }


  }

  .liegen_Probleme .wrapper_problems {
    margin-top: 60px;
  }
  .liegen_Probleme .problems_box {
    height: 250px;
    margin-bottom: 20px;
  }

.spader_non{
  display: none;
}
  .Help .Help_text {
    align-items: start;
    width: 100vw;
    height: auto;
    margin: 70px 20px 0 20px;

    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 38px;
      display: flex;
      align-items: start;
      color: #FFFFFF;
      z-index: 50;
      margin-top: -30px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      text-align: start;

    }
    h3{
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 38px;
      letter-spacing: 0.005em;
     z-index: 50;
      margin-bottom: 30px;
      align-items: start;
    }
  }

  .box_wrapper_help {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    justify-content: space-around;
    margin: 40px 20px 0 20px;

  }

  .box_wrapper_help .box_help {
    width: 333px;
    height: 300px;
    padding: 50px 20px 20px 20px;
    margin-bottom: 40px;

  }
  .What_Apart .comp_help img {
    width: 304.46px;
    height: 243.87px;
    margin-top: 70px;
    margin-bottom: 50px;

  }
  .What_Apart .SpiderLeft img {
    width: 120px;
    height: 340px;
    margin-left: 240px;
    margin-top: -1100px;
   display: none;

  }
  .What_Apart .Apart_text {
    margin: 10px 20px 20px 20px;

  }

  .Unique_wrapper .Unique_boxes {
    margin: 0;

  }

  .Unique_wrapper{
    background-image: none;
    margin-top: 60px;
    margin-bottom: 60px;

    box-shadow: none;
  }

  .Unique_wrapper .Unique_box {
    width: 340px;
    height: auto;
    border-style: none;
    padding: 0;

    margin: 0 20px 0 20px;

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.1px;
      color: #52515F;
      opacity: 0.75;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: #52515F;
    }
  }

  .What_Apart .Apart_text {

    width: auto;

  }
  .Fields_wrapper {

    height: auto;
  }
}