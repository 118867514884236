.home_benefits {
  display: flex;
  align-items: center;
  width: 100vw;


}

.benefits_background {
  background: url("./imageBenefits/img_3.png") no-repeat;
  background-size: cover;


}

.Fields_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;


}

.Fields_Data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100vw;
  flex-wrap: wrap;
  margin: 170px 150px 0 150px;

  .Fields_img {
    width: 342.01px;
    height: 354px;
    img{
      margin-top: 60px;
    }

  }

  .Fields_text {
    width: 700px;


    h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      color: #FFFFFF;
    }

    hr {
      width: 69px;
      height: 3px;
      background: #009FBF;
      border-radius: 3px;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.1px;
      color: #FFFFFF;
    }
  }

  .Fields_image {
    display: flex;
    align-items: center;
    width: 290px;
    height: 290px;
    justify-content: center;


    img {
      width: 300px;
      height: 300px;

    }
  }

}


.benefits_wrapper_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100vw;
  justify-content: center;


.boxs_center{
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 200px 0 150px 0;
}
  .benefits_box {
    display: flex;
    flex-direction: row;
    width: 315px;
    height: 237px;
    flex-wrap: wrap;
    background: linear-gradient(180deg, rgba(180, 183, 185, 0.234) 0%, rgba(11, 12, 13, 0.0207187) 30.73%, rgba(180, 183, 185, 0.0624) 88.02%);
    box-shadow: 0 4px 4px rgba(33, 12, 12, 0.25);
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 30px 40px 20px 40px;
    p::before{
      content: " - "; /* Левая кавычка */
      font-size: 20px; /* Размер текста кавычек */
      color: white; /* Цвет кавычек */

    }

    p {

      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 128%;
      color: #FFFFFF;


    }

    h3 {

      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #009FBF;
      opacity: 0.75;


    }


  }


}


.home_benefits_2 {
  display: flex;
  align-items: center;
  width: 100vw;
  background: linear-gradient(179.48deg, #009FBF -55.96%, #202F3D 22.68%);
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;



}

.Profit_wrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content:center;
  margin-top: 100px;
  margin-bottom: 150px;
  .Profit_margin{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 1200px;
  }




}

.Profit_wrapper_text {

  display: flex;
  align-items: start;
  justify-content:center;
  flex-direction: column;


  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #FFFFFF;
    line-height: 25px;

  }

  hr {
    width: 69px;
    height: 3px;
    background: #009FBF;
    border-radius: 3px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: white;

  }
}
.Profit_boxes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 1200px;


  .Profit_box {
    display: flex;
    align-items: start;
    justify-content: space-around;
    width: 277px;
    height: 250.44px;
    flex-direction: column;
    margin-bottom: 100px;

    h3 {
      margin-top: 30px;
      width: 249px;
      height: 55.32px;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #FFFFFF;
    }

    p {
      width: 260px;
      height: 90.52px;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      color: #FFFFFF;

    }

    img {
      height: 60px;
    }
  }
}


@media screen and (max-width: 1350px){
  .Profit_wrapper .Profit_margin {
    width: 1100px;
  }

  .benefits_wrapper_box {

    .boxs_center{
      display: flex;
      width: 100vw;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 0 0;
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
    margin: 70px 0 50px 0;
  }
  .Fields_Data {
    justify-content: center;
    margin: 175px 20px 0 20px;
    .Fields_text {
      width: auto;
      justify-content: start;
    }
  }


  .Fields_Data {
    justify-content: center;
    .Fields_img {
      margin-top: 50px;

    }
  }
  .Profit_boxes {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
  }

}

@media screen and (max-width: 1107px){
  .Profit_wrapper {
    margin-bottom: 70px;
    margin-top: 70px;
    .Profit_margin {
      width: 800px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }


}
@media screen and (max-width: 831px){
  .Profit_wrapper {

    .Profit_margin {
      width: 550px;
      margin-top: 60px;
      margin-bottom: 50px;

    }
  }


}

@media screen and (max-width: 780px) and (max-width: 1000px) and (max-width: 1200px) {

  .Profit_wrapper .Profit_margin {
    margin-top: 0;
    margin-bottom: 0;

  }
  .Profit_wrapper_text {
    p {
      text-align: start;
    }
  }
  .Fields_Data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100vw;
    flex-wrap: wrap;
    margin: 210px 20px 30px 20px;

    .Fields_text {
      width: auto;

      h3 {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #00B0D3;
      }

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 154.69%;
        color: #FFFFFF;
        margin-bottom: 10px;


      }
    }


    .Fields_Data .Fields_text h2 {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 119.19%;
      color: #FFFFFF;
    }
  }

  .benefits_wrapper_box {

    .boxs_center{
      display: flex;
      width: 100vw;
      justify-content:space-around;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 0 0;
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 30px 0 50px 0;
    width: 100vw;




    .Fields_wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100vw;
      height: 100vh;
    }

    .Fields_wrapper {

      height: auto;
    }

  }
  .benefits_background_2 {
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .Profit_wrapper {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: start;
    margin: 0;


    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 45px;
      color: #FFFFFF;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.1px;
      color: #FFFFFF;
    }

    img {
      display: none;
    }

  }


  .Profit_boxes {
    margin: 50px 0 50px 0;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    width: 100vw;
    .Profit_box {
      width: 340px;
      margin-bottom: 30px;
      margin-left: 20px;

      h3 {
        width: 320px;
        height: 50px;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.1px;
        color: #FFFFFF;
      }

      p {
        width: 340px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
      }

    }

  }
  .Profit_wrapper {
    padding-left: 0;
    margin-top: 50px;
    margin-bottom: 0;

    .Profit_wrapper_text {
      width: 320px;
      margin: 20px 0 0 20px;

      p {
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        margin: 0;
      }
    }
  }

  .Fields_Data .Fields_img {
 margin-top: 50px;
    margin-bottom: 50px;

  }
}