.carusel_wrapper{
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: start;
  width:100vw;
  margin-top: -50px;
}
.rec.rec-arrow-left:hover:enabled, .rec.rec-arrow-right:hover:enabled {
  box-shadow:none;
  background-color: #009fbf;
  color: white;

}
.rec.rec-arrow:active {
  background-color: #009FBF;
}
.eWjCzc {

  background-color: rgb(241, 236, 236);


  box-shadow: none;
  border: none;
}

.eWjCzc:hover:enabled, .eWjCzc:focus:enabled {

  background-color: #009fbf;
  box-shadow: 0 0 2px 0 #2f2e2e;
}

.rec.rec-carousel-wrapper .rec-pagination .rec.rec-dot.rec.rec-dot_active {
  background: #009fbf;
}
.rec.rec-carousel-wrapper .rec-pagination .rec.rec-dot.rec {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.1);
}
.rec.rec-arrow:disabled {
  visibility: hidden;
}

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}



.grUxXV {

  margin: 0 50px 0 50px;
}
@media screen and (max-width: 1450px){
  .main_carusel_wrapper {

    justify-content: start;

  }
  .grUxXV {

    margin:0;
  }
}

@media screen and (max-width: 1200px) {
  .rec.rec-carousel-wrapper .rec-carousel button {
    display: none;
  }
  .grUxXV {

    margin:0;
  }

  .carusel_wrapper{
    margin-top: -70px;
  }
}
@media screen and (max-width: 980px) {
  .rec.rec-carousel-wrapper .rec-carousel button {
    display: none;
  }
}
@media screen and (max-width: 780px) {
  .rec.rec-carousel-wrapper .rec-carousel button {
    display: none;
  }
}