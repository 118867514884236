.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 30px;
  background: #009fbf;
  color: white;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: none;
  box-shadow: 0 5px 10px #ccc;
}

.back-to-top:hover {
  background: #58a0af;
}



.pulse-button:hover .pulse-button__icon {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transition: .3s;
}

.pulse-button:hover .pulse-button__text {
  transition: .3s;
  opacity: 1;
  visibility: visible;
  position: relative;
}

.pulse-button__rings {
  border: 1px solid  #009fbf;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;
  border-radius: 50%;
  animation-duration: 2.0s;
  animation-name: pulse_1;
  animation-iteration-count: infinite;
  z-index: -1;
}

.pulse-button__rings:nth-child(2) {
  animation-name: pulse_2;
}

.pulse-button__rings:nth-child(3) {
  animation-name: pulse_3;
}

@keyframes pulse_1 {
  from {
    opacity: 1;
    transform: scale(0.9, 0.9);
  }

  to {
    opacity: 0;
    transform: scale(1.3, 1.3);
  }
}

@keyframes pulse_2 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
}

@keyframes pulse_3 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 0;
    transform: scale(1.7, 1.7);
  }
}
